import "core-js/modules/es.array.push.js";
import editDiv from "@/view/admin/adminEdit.vue";
import { importFile, getIndustryList } from "@/api/api";
export default {
  name: "menuList",
  components: {
    editDiv
  },
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getIndustryList().then(res => {
        this.tableData = res.data;
      });
    },
    powerEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/admin/userPower",
        query: {
          info: data
        }
      });
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
      let data = '';
      if (row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data); //调用子组件方法
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.limit = e;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getList();
    },
    downFile() {
      let a = document.createElement("a"); //创建一个<a></a>标签
      a.href = "/template/industry.xlsx"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      a.download = "行业导入模板.xlsx"; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      a.style.display = "none"; // 障眼法藏起来a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
    },
    beforeAvatarUpload(e) {
      let formData = new FormData();
      formData.append('file', e);
      importFile(formData, '/admin/admin_system/industry_import').then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.getList();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};